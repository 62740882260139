<template> 
<div class='ternaryToggle' :class='{ animating }'> 
	<div class='scene3d'>
		<div class='cube' :class='face'>
			<div class='miniButton success right' @click.stop='onClick(false)'>Edit</div>
			<div class='miniButton primary front' @click.stop='onClick("readWrite")'>View</div>						
			<div class='miniButton neutral left' @click.stop='onClick("readOnly")'>Off</div>
		</div>
	</div>
	<div class='scene' :class='face'>
		<div class='miniButton success right' @click.stop='onClick(false)'>Edit</div>
		<div class='miniButton primary front' @click.stop='onClick("readWrite")'>View</div>						
		<div class='miniButton neutral left' @click.stop='onClick("readOnly")'>Off</div>
	</div>
</div> 
</template> 
 
<script>
export default {
	name: 'TernaryToggle',
	props: ['value'],
	data() { return { animating: false, timer: null }},
	computed: {
		face() {
			if (this.value==='readWrite') return 'showRight'
			else if (this.value==='readOnly') return 'showFront'
			else return 'showLeft'
		}
	},
	methods: {
		onClick(v) {
			clearTimeout(this.timer)
			this.animating = true
			this.timer = setTimeout(()=>this.animating=false, 1000)
			this.$emit('toggle', v)
		}
	}
}
</script>

<style lang='scss'>
.ternaryToggle {
	position: relative;

	&:hover {
		.scene3d { display: block; }
	}
	&:not(:hover) {
		.scene { display: block; }		
	}
	&.animating {
		.scene3d { display: block; }
		.scene { display: none; }				
	}

	.scene {
		display: none;
		width: 40px;
		height: 24px;
		position: relative;	
		> div {
			position: absolute;
			width: 40px;
			height: 24px;
			border-radius: 0;			
			display: none;
		}	
		&.showFront > div:nth-child(2) { display: flex; }
		&.showLeft > div:nth-child(3) { display: flex; }
		&.showRight > div:nth-child(1) { display: flex; }
	}

	.scene3d {
		width: 40px;
		height: 24px;
		perspective: 120px;
		display: none;
	}

	.cube {
		width: 100%;
		height: 100%;
		position: relative;
		transform-style: preserve-3d;	
		transform: translateZ(-20px);	
		transition: transform 1s;
		&.showFront { transform: translateZ(-20px) rotateY(   0deg); }
		&.showLeft { transform: translateZ(-20px) rotateY(  90deg); }
		&.showRight { transform: translateZ(-20px) rotateY(  -90deg); }
		> div {
			position: absolute;
			width: 40px;
			height: 24px;
			border-radius: 0;
			&.front { transform: rotateY(  0deg) translateZ(20px); }
			&.right { transform: rotateY(  90deg) translateZ(20px); }
			&.left { transform: rotateY(  -90deg) translateZ(20px); }
		}
	}



	.miniButton {
		z-index: 1; 
		width: 40px;
		&.neutral { border: 1px solid $color-neutral; }
/*
		.ternaryMenu {
			position: absolute;
			top: -26px;
			left: -1px;
			background: #fff;
			width: 100%;
			z-index: 20;
			box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);
			border-radius: 2px;
			padding: 1px;
			transition: opacity 200ms linear;
			opacity: 0;
			pointer-events: none;
			color: $color-black;
			transition-delay: 200ms;
			> div:not(:last-child) { margin-bottom: 1px; }
			> div { border-radius: 2px;  padding: 0 $size-gutter * 2; }
			&:after {
				position: absolute;
				top: -24px;
				left: 0;
				height: 25px;
				width: 100%;
				content: '';
			}
		}

		&:hover {
			.ternaryMenu {
				opacity: 1;
				pointer-events: auto;
			}
		}
		*/
	}

}
</style>