import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'

export const dateTime = { 
    data: () => ({ langBackdoor: 0 }),
    computed: {
        dayJsLang() { return this.$store.state.profile.user.lang }
    },
    methods: {
        dateTime(str) {
            return dayjs(str).format('MMM D YYYY, H:mm')
        },
        date(str, includeYear = true) {
            return dayjs(str).format(includeYear ? 'MMM D YYYY' : 'MMM D')
        }
    },
    watch: {
        dayJsLang(lang) {
            this.langBackdoor++
            if (lang) dayjs.locale(lang)
        }
    },
    created() {
        dayjs.locale(this.dayJsLang)
    }
} 